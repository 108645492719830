import React, { Component } from 'react';

const AuthContext = React.createContext();

const INITIAL_STATE = { user: {} , validToken: false, error: {}, project: null};

class AuthProvider extends Component {
  state = INITIAL_STATE;

  //setta le informazione dell'utente o l'errore dato dal controllo
  setUserState = (status, data) => {
    if(status === 200){
      this.setState({ user: data , validToken: true});
    }
  }

  //setta l'id del progetto inviato tramite url
  setProject = (idProgetto) => {
    if(idProgetto.length > 0)
      sessionStorage.setItem('project', idProgetto);
    else
      sessionStorage.setItem('project', 0);
  }

  setError = (response) => {
    this.setState({ error: response , validToken: false});
  }
  
  render() {
    return (
      <AuthContext.Provider
        value={{...this.state, setUserState: this.setUserState, setProject: this.setProject, setError: this.setError}}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
const AuthConsumer = AuthContext.Consumer
export { AuthProvider, AuthConsumer, AuthContext}