import { faMapSigns } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import LogNonTrovati from '../../assets/fogg-page-not-found.png';
import { JUSTIFICATIONNET } from '../../navigation/routes';
import TableBudgetNet from '../TableBudgetNet';
import styles from './LogBudgetNetProject.module.css';


const LogJustificationNetProjectComponent = ({ logsList, idProgetto}) =>
<>
<Container className={styles.home}>
  <p>
    Benvenuto nella sezione: LOG Total Budget &nbsp;&nbsp;
    <Link to={JUSTIFICATIONNET + "/" + idProgetto}>
      <Button color="warning" className="text-left"> 
        <FontAwesomeIcon icon={faMapSigns} /><span className={styles.smallLabel}>&nbsp; Vai a Log Budget Justification<small></small></span>
      </Button>
    </Link>
  </p>
  {logsList.length === 0 && <div><img src={LogNonTrovati} alt="Nessun Log trovato" width="65%" height="65%" /><br/><p className="text-muted">Nessun log trovato per l'ID Progetto inserito</p></div>}
  
</Container>
<div className={styles.tableMargins}>
  {logsList.length > 0 && <TableBudgetNet logsList={logsList} />}
</div>
</>

LogJustificationNetProjectComponent.propTypes = {
  logsList: PropTypes.array,
  idProgetto: PropTypes.number,
};

LogJustificationNetProjectComponent.defaultProps = {
  logsList: [],
};

export default LogJustificationNetProjectComponent;