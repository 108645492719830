import api from '../../api';
import LogJustificationNetProjectComponent from './LogJustificationNetProjectComponent';
import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { ERRORPAGE } from '../../navigation/routes.js';
import {AuthContext} from '../../authentication/AuthContext.js';



const LogJustificationNetProjectContainer = ({match, history}) =>{ 
  
  const [logs, setLogs] = useState([]);
  const idProgetto = parseInt(match.params.id);
  const { setError } = useContext(AuthContext); 
  const goto = location => history.push(location);

  useEffect(() => {
    const fetchData = async (idProgetto) => {
      await api.getNetProjectJustificationLogs(idProgetto).then( response => {
        setLogs(response.data)})
      .catch ( error => {
        setError(error.response)
        goto(ERRORPAGE);
      });
    }

    fetchData(idProgetto);
    
  },[])


  return ( <LogJustificationNetProjectComponent logsList={logs} idProgetto={idProgetto}/> )
};

LogJustificationNetProjectContainer.propTypes = {};

LogJustificationNetProjectContainer.defaultProps = {};

export default withRouter(LogJustificationNetProjectContainer);