import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import moment from 'moment';
import 'react-table/react-table.css';
import _ from "lodash";



const columns = [
    {
        Header: "Intestazione",
              columns: [
                {
                    Header: 'Codice WP',
                    accessor:'codice',
                    maxWidth: 230
                },
                {
                    Header: 'Data Modifica',
                    id:'dataModifica',
                    accessor: d => { return moment(d.dataModifica).format('DD/MM/YYYY HH:mm:ss'); },
                    aggregate: () => 'Seleziona per vedere i log',
                    minWidth: 150,
                    maxWidth: 210
                }
            ]
    },
    {
        Header: "Spese",
              columns: [
                {
                    Header: 'Voce di Costo',
                    id: 'descrizione',
                    accessor: d => d.descrizione,
                    aggregate: () => 'Totale'
                },
                {
                    Header: 'UO 1',
                    accessor:'unit1',
                    aggregate: vals => _.sum(vals)
                },
                {
                    Header: 'UO 1 - richiesta MOH',
                    accessor:'unit1MOH',
                    aggregate: vals => _.sum(vals)
                },
                {
                    Header: 'Year 1',
                    accessor:'year1',
                    aggregate: vals => _.sum(vals)
                }
            ]
    }
]


const Table = ({logsList}) =>
<ReactTable
    data={logsList}
    columns={columns}
    defaultPageSize={10}
    pivotBy={["codice", "dataModifica"]}
    className="-striped -highlight"
  />


Table.propTypes = {
    logsList: PropTypes.array
};

Table.defaultProps = {};

 export default Table;
