import { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import api from '../../api';
import { CODENET } from '../../constants';
import { BUDGET, BUDGETNET, ERRORPAGE } from '../../navigation/routes.js';
import {AuthContext} from '../../authentication/AuthContext.js';


const RedirectContainer = ({match, history}) =>{ 
  
  //accede alle info dell'utente nel Context e all'id progetto inviato tramite url
  const { user , setError } = useContext(AuthContext); 

  const [isNet, setisNet] = useState(false);
  const [datiProgetto, setDatiProgetto] = useState({});
  const [control, setControl] = useState(false);
  const goto = location => history.push(location);
   
  const checkProject = async () => {
      await api.getProjectDetails(sessionStorage.getItem("project"))
        .then( response => {
          if(response.data.codiceTipologia === CODENET){
            setisNet(true);
            setDatiProgetto(response.data);
            setControl(true);
          } else {
            setisNet(false);
            setDatiProgetto(response.data);
            setControl(true);
        }})
        .catch ( error => {
          setError(error.response)
          goto(ERRORPAGE);
        });
    }

  //se l'utente è loggato cerco le informazioni del progetto a lui associato e controllo se è di tipo Net
  //in base a questa distinzione faccio una redirect alla pagina corretta per visualizzare i log
  useEffect( () => {
  
  if(user.auth_info && !control){
    checkProject();
  }

  if (control) {
    const dest = isNet ? BUDGETNET + '/' + datiProgetto.id : BUDGET + '/' + datiProgetto.id;
    goto(dest);
  }

  },[isNet, datiProgetto.id, control])
  return ( null );
};

RedirectContainer.propTypes = {};

RedirectContainer.defaultProps = {};

export default withRouter(RedirectContainer);