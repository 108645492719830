import React from 'react';

import NavbarComponent from './NavbarComponent';

const NavbarContainer = () => {
  return (
    <NavbarComponent/>
  );
};

NavbarContainer.propTypes = {};

NavbarContainer.defaultProps = {};

export default NavbarContainer;