import api from '../../api';
import LogBudgetNetProjectComponent from './LogBudgetNetProjectComponent';
import React, { useEffect, useState , useContext} from 'react';
import { withRouter } from 'react-router-dom';
import { ERRORPAGE } from '../../navigation/routes.js';
import {AuthContext} from '../../authentication/AuthContext.js';



const LogBudgetNetProjectContainer = ({match, history}) =>{ 
  
  const [logs, setLogs] = useState([]);
  const idProgetto = parseInt(match.params.id);
  const { setError } = useContext(AuthContext); 
  const goto = location => history.push(location);

  useEffect(() => {
    const fetchData = async (idProgetto) => {
      await api.getNetProjectBudgetLogs(idProgetto).then( response => {
        setLogs(response.data)})
      .catch ( error => {
        setError(error.response)
        goto(ERRORPAGE);
      });
    }

    fetchData(idProgetto);
    
  },[])


  return ( <LogBudgetNetProjectComponent logsList={logs} idProgetto={idProgetto}/> )
};

LogBudgetNetProjectContainer.propTypes = {};

LogBudgetNetProjectContainer.defaultProps = {};

export default withRouter(LogBudgetNetProjectContainer);