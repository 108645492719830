import React from 'react';
import { withRouter } from 'react-router-dom';
import { Navbar, NavbarBrand } from 'reactstrap';
import logo from '../../assets/logo_ministero.png';



const NavbarComponent = ({history, location: { pathname }}) => {
    

    return (
        <Navbar color="primary" dark expand="md">
            <NavbarBrand>
                <img src={logo} alt="MINISTERO" width="400" height="60" />
            </NavbarBrand>

        </Navbar>
    );
}

NavbarComponent.propTypes = {};

NavbarComponent.defaultProps = {};

export default withRouter(NavbarComponent);