import React, {useState, useEffect, useContext} from 'react';
import { withRouter } from 'react-router-dom';

import LogBudgetComponent from './LogBudgetComponent';
import api from '../../api';
import { ERRORPAGE } from '../../navigation/routes.js';
import {AuthContext} from '../../authentication/AuthContext.js';



const LogBudgetContainer = ({match, history}) =>{ 
  
  const [logs, setLogs] = useState([]);
  const idProgetto = parseInt(match.params.id);
  const { setError } = useContext(AuthContext); 
  const goto = location => history.push(location);

  useEffect(() => {
    const fetchData = async (idProgetto) => {
      await api.getProjectBudgetLogs(idProgetto).then( response => {
        setLogs(response.data)})
      .catch ( error => {
        setError(error.response)
        goto(ERRORPAGE);
      });
    }

    fetchData(idProgetto);
  
  },[])


  return ( <LogBudgetComponent logsList={logs} idProgetto={idProgetto}/> )
};

LogBudgetContainer.propTypes = {};

LogBudgetContainer.defaultProps = {};

export default withRouter(LogBudgetContainer);