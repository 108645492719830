import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import ProgettoNonTrovato from '../../assets/fogg-error-404.jpg';
import FatalError from '../../assets/fogg-fatal-error.jpg';
import { AuthContext } from '../../authentication/AuthContext.js';
import { Alert , Container} from 'reactstrap';

const showError = (error) => {
  if(error.status === 404){
    return (
      <div>
        <img src={ProgettoNonTrovato} alt="Nessun Log trovato" width="45%" height="45%" />
        <br/>
      </div>
    )
  }
  if(error.status === 403 || error.status === 401){
    return (
      <div>
        <img src={FatalError} alt="Sessione Scaduta" width="45%" height="45%" />
        <br/>
      </div>
    )
  }

}

const ErrorPaige = () => {
  const { error } = useContext(AuthContext); 
  
  return(
    <>
    <div>
      {showError(error)}   
    </div>
    <Container>
      {error.data && <Alert color="danger"> {error.data.message}</Alert> }
    </Container>
    </>
  )

}

ErrorPaige.propTypes = {};

ErrorPaige.defaultProps = {};

export default withRouter(ErrorPaige);