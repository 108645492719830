import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import api from '../../api';
import { AuthContext } from '../../authentication/AuthContext';
import { AuthService } from '../../authentication/AuthService.ts';
import AppRoutes from '../../navigation/AppRoutes';
import Navbar from '../Navbar';
import './App.css';

let AuthServiceObj = new AuthService();

const AppComponent = () => {
  
  const {user, setUserState, setProject, setError} = useContext(AuthContext); 

  //salva i dati dell'utente oppure il messaggio di errore
  const getUserInfo = async () => {
    await api.getUser()
      .then( response => {
        setUserState(response.status, response.data);
      })
      .catch( error => {
        setError(error.response.data);
      }
    );
  }

  //valuta se l'utente è loggato oppure no - se loggato o dopo login redirecta a /redirect
  const checkUser = () => {
    AuthServiceObj.getUser().then(utente => {
      if (utente) {
        axios.defaults.headers.common['Authorization'] = utente.access_token;
        getUserInfo();
      } else {
        AuthServiceObj.login();
      }
    });
  };
  
  useEffect(() => {
    if(!sessionStorage.getItem('project')){
      setProject(window.location.pathname.split('/')[1]);
      
    } //gestione del parametro inviato tramite url
      
    checkUser();
  },[user.sub])

  return(
    <div className="App">
        <Router>
          <>
            <Navbar />
            <AppRoutes />
          </>
        </Router>
    </div>
  );
}

AppComponent.propTypes = {};

AppComponent.defaultProps = {};

export default AppComponent;