import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';

import { AuthContext } from '../authentication/AuthContext.js';

const AuthRoute = ({component: Component, claim, ...rest}) => {
  const { validToken } = useContext(AuthContext);

  // tutto ok
  if ( validToken )
    return (
      <Route 
        render={props => <Component {...props} />} 
        {...rest}
      /> 
    );

  // autenticazione da fare
  if (!validToken)
  return (
    <Route 
      render={props => <Redirect to={{pathname:'/', state: {from: props.location}}} />} 
      {...rest}
    /> 
  );

};

AuthRoute.propTypes = {};

AuthRoute.defaultProps = {};

export default AuthRoute;