import axios from 'axios';

// const waitTimeout = async s => new Promise(resolve => setTimeout(resolve, s * 1000));

const apiConfig = {
  //development: 'http://localhost:8080/',
  development: 'http://192.168.4.225:8086/',
  production: 'https://wf-rest.cbim.it/',
};

const env = process.env.NODE_ENV || 'development';
const baseURL = apiConfig[env];
const instance = axios.create({ baseURL });


const getProjectBudgetLogs = (projectID) => {
  const url = 'api/logs/budget/' + projectID;
  return instance.get(url);
};

const getProjectJustificationLogs = (projectID) => {
  const url = 'api/logs/justification/' + projectID;
  return instance.get(url);
}

const getProjectDetails = (projectID) => {
  const url = 'api/logs/projectDetails/' + projectID;
  return instance.get(url);
}

const getNetProjectJustificationLogs = (projectID) => {
  const url = 'api/logs/net/justification/' + projectID;
  return instance.get(url);
}

const getNetProjectBudgetLogs = (projectID) => {
  const url = 'api/logs/net/budget/' + projectID;
  return instance.get(url);
}

const getUser = () => {
  const url = 'api/user';
  return instance.get(url);
}

export default {
  getProjectBudgetLogs,
  getProjectJustificationLogs,
  getProjectDetails,
  getNetProjectJustificationLogs,
  getNetProjectBudgetLogs,
  getUser
};
