import React, { useEffect } from 'react';
import Oidc from 'oidc-client';
import Constants from '../../constants';

const Callback = () => {

    const redirectURL = `${Constants.clientRoot}/redirect`;

    const callbackFun = () => {
        let mgr = new Oidc.UserManager();
            mgr.signinRedirectCallback().then(function () {
                window.history.replaceState({},
                    window.document.title,
                    window.location.origin);
                window.location = redirectURL;
            }, function (error) {
                console.error(error);
            });
    }

    useEffect(() => {
        callbackFun();
      },[])

    return (
        <p>Authentification callback processing...</p>
    );
}

export default Callback;