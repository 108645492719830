import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as routes from './routes';
import AuthRoute from './AuthRoute';
import LogBudget from '../components/LogBudget';
import LogJustification from '../components/LogJustification';
import LogJustificationNetProject from '../components/LogJustificationNetProject';
import LogBudgetNetProject from '../components/LogBudgetNetProject';
import Redirect from '../components/Redirect';
import Error from '../components/Error';
import Callback from '../components/Callback';

export const AppRoutes = () =>
  <Switch>
    <Route exact path={routes.REDIRECT} component={() => <Redirect />} />
    <Route exact path={routes.CALLBACK} component={() => <Callback />} />
    <Route exact path={routes.ERRORPAGE} component={() => <Error />} />
    <AuthRoute exact path={`${routes.BUDGET}/:id`} component={() => <LogBudget />} />
    <AuthRoute exact path={`${routes.JUSTIFICATION}/:id`} component={() => <LogJustification />} />
    <AuthRoute exact path={`${routes.JUSTIFICATIONNET}/:id`} component={() => <LogJustificationNetProject />} />
    <AuthRoute exact path={`${routes.BUDGETNET}/:id`} component={() => <LogBudgetNetProject />} /> 
  </Switch>

export default AppRoutes;
